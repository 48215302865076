import { createRouter, createWebHistory } from 'vue-router'  
import Main from '@/views/Main.vue'  
import Projects from '@/views/Projects.vue'  
import Impressum from '@/views/Impressum.vue'  
import AppDatenschutz from '@/views/Datenschutz.vue'  
  
const routes = [  
  {  
    path: '/',  
    name: 'Main',  
    component: Main  
  },  
  {  
    path: '/projects',  
    name: 'Projects',  
    component: Projects  
  },  
  {  
    path: '/impressum',  
    name: 'Impressum',  
    component: Impressum  
  },  
  {  
    path: '/datenschutz',  
    name: 'Datenschutz',  
    component: AppDatenschutz  
  }  
]  
  
const router = createRouter({  
  history: createWebHistory(process.env.BASE_URL),  
  routes  
})  
  
export default router  
