// src/main.js  
import { createApp } from 'vue';  
import App from './App.vue';  
import router from './router';  
import CookieConsentVue from './plugins/CookieConsentVue';  
  
import "bootstrap/dist/css/bootstrap.min.css";  
import "bootstrap/dist/js/bootstrap.bundle.min";  
import "./assets/global.css";  
import "animate.css/animate.min.css";  
  
// Import Font Awesome  
import { library } from "@fortawesome/fontawesome-svg-core";  
import { faChevronLeft, faChevronRight, faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";  
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";  
  
library.add(faChevronLeft, faChevronRight, faQuoteLeft, faQuoteRight);  
  
const app = createApp(App);  
app.component("font-awesome-icon", FontAwesomeIcon);  
app.use(router);  
  
app.use(CookieConsentVue, {  
  categories: {  
    necessary: {  
      enabled: true,  
      readOnly: true  
    },  
    analytics: {}  
  },  
  language: {  
    default: 'de',  
    translations: {  
      de: {  
        consentModal: {  
          title: 'Wir verwenden Cookies',  
          description: 'Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website machen.',  
          acceptAllBtn: 'Alle akzeptieren',  
          acceptNecessaryBtn: 'Nur notwendige akzeptieren',  
          showPreferencesBtn: 'Einstellungen verwalten'  
        },  
        preferencesModal: {  
          title: 'Cookie-Einstellungen',  
          acceptAllBtn: 'Alle akzeptieren',  
          acceptNecessaryBtn: 'Nur notwendige akzeptieren',  
          savePreferencesBtn: 'Einstellungen speichern',  
          sections: [  
            {  
              title: 'Notwendige Cookies',  
              description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich.',  
              linkedCategory: 'necessary'  
            },  
            {  
              title: 'Analytische Cookies',  
              description: 'Diese Cookies helfen uns zu verstehen, wie unsere Website genutzt wird.',  
              linkedCategory: 'analytics'  
            },  
            {  
              title: 'Mehr Informationen',  
              description: 'Weitere Details zu Cookies und wie Sie diese verwalten können, finden Sie in unserer <a href="/datenschutz">Datenschutzerklärung</a>.'  
            }  
          ]  
        }  
      }  
    }  
  }  
});  
  
app.mount("#app");  
