<template>
  <div>
    <!-- Contact -->
    <div id="contact">
      <h2 class="my-4">Kontakt</h2>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="contact">
            <p>A.Z. Trockenbau</p>
            <p>Inhaber: Agron Zeneli</p>
            <p>Telefon: +49 157 79165183</p>
            <p>Email: <a href="mailto:info@trockenbau-az.de">info@trockenbau-az.de</a></p>
            <p>Nordhausener Str. 22, 74336 Brackenheim, Deutschland</p>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>  
    
<script>
export default {
  name: 'ContactView',
}
</script>  

<style scoped>
.contact {
  text-align: center;
}

.contact p {
  margin-bottom: 5px;
}
</style> 
  