<template>  
    <footer class="text-white mt-5 py-4">  
        <div class="container">  
            <div class="row">  
                <div class="col-md-6">  
                    <p>&copy; 2023 A.Z. Trockenbau. All Rights Reserved.</p>  
                </div>  
                <div class="col-md-6 text-md-right d-flex justify-content-end">  
                    <router-link class="footer-link" to="/impressum">Impressum</router-link>  
                    <router-link class="footer-link" to="/datenschutz">Datenschutz</router-link>  
                </div>  
            </div>  
        </div>  
    </footer>  
</template>    
      
<script>  
export default {  
    name: 'AppFooter',  
};  
</script>    
      
<style scoped>  
  footer {  
      background-color: #e74c3c;  
  }  
  
  .footer-link {  
      margin-right: 15px;
      text-decoration: none;
      color: #ffffff;  
  }  

  .footer-link:hover {  
    color: #000; /* Change color on hover, same as navbar-small links */  
    text-decoration: none; /* Remove underline on hover */  
  }  
</style>  
