<template>
  <div>
    <section id="hero" class="parallax" :style="`background-image: url(${randomMainImage})`">
      <div class="container">
        <h1 class="text-center title_text animate__animated animate__heartBeat">
          A.Z. Trockenbau
        </h1>

        <p class="text-center title_text animate__animated animate__backInDown">Ihr Experte für Trockenbau und Innenausbau</p>
      </div>
    </section>

    <section id="about" class="scroll-padding">
      <div class="container">
        <h2>Über uns</h2>
        <p>Unser Team bei A.Z. Trockenbau versteht, dass die Qualität der Innenausbauarbeiten entscheidend für
                    das Erscheinungsbild und den Komfort Ihres Zuhauses oder Geschäftsraums ist. Wir setzen auf
                    meisterhafte Präzision und hochwertige Materialien, um Ihnen makellose Raumgestaltung und
                    langanhaltende Ergebnisse zu garantieren. <br>

                </p>
                <p>Mit 22 Jahren Erfahrung und umfassendem Fachwissen in der Trockenbauindustrie sind wir bestrebt,
                    jeden Kundenwunsch zu erfüllen und maßgeschneiderte Lösungen für jedes Projekt zu bieten. Von der
                    Planung bis zur Fertigstellung arbeiten wir eng mit Ihnen zusammen, um Ihre Vision in die Realität
                    umzusetzen und Ihnen ein Ergebnis zu liefern, auf das Sie stolz sein können. <br>

                </p>

                <p>Überzeugen Sie sich selbst über unsere Leistungen in unserer <router-link to="projects">Bildgergalerie</router-link>.</p>
                <p>Unsere Experten für Trockenbau, Gipskarton und Innenausbau stehen bereit, um Ihnen bei der
                    Verwirklichung Ihrer Wohn- oder Geschäftsträume zu helfen. Kontaktieren Sie uns noch heute, um mehr
                    über unsere umfassenden Dienstleistungen zu erfahren und ein unverbindliches Angebot zu erhalten.
                </p>
      </div>
    </section>

    <section id="services" class="scroll-padding">
      <div class="container">
        <h2>Dienstleistungen</h2>
        <div class="row">
          <div class="col-md-3">
            <div class="service-tile animate__animated animate__fadeInLeft">

              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/trockenbau_optimized.png')})`">
              </div>
              <div class="service-tile-content">
                <h3>Trockenbau</h3>
                <p>
                  Wir bieten professionelle Trockenbauarbeiten für Wände und Decken,
                  um Ihre Räume optimal zu gestalten.
                </p>
              </div>
              <div class="service-tile-image-overlay"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="service-tile animate__animated animate__fadeInLeftBig">
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/deckensysteme_optimized.png')})`"></div>
              <div class="service-tile-content">
                <h3>Deckensysteme</h3>
                <p>
                  Unsere Experten installieren abgehängte Decken und
                  Deckenverkleidungen für eine verbesserte Raumakustik und
                  Ästhetik.
                </p>
              </div>
              <div class="service-tile-image-overlay"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="service-tile animate__animated animate__fadeInRightBig">
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/spachtelarbeiten_optimized.png')})`"></div>
              <div class="service-tile-content">
                <h3>Spachtelarbeiten</h3>
                <p>
                  Wir führen Spachtel- und Verputzarbeiten durch, um eine glatte
                  Oberfläche für die weitere Gestaltung Ihrer Räume zu schaffen.
                </p>
              </div>
              <div class="service-tile-image-overlay"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="service-tile animate__animated animate__fadeInRight">
              <div class="service-tile-image" :style="`background-image: url(${require('@/assets/reparatur_optimized.png')})`"></div>
              <div class="service-tile-content">
                <h3>Reparatur</h3>
                <p>
                  Unsere Trockenbaufirma bietet auch Reparaturdienstleistungen für
                  bestehende Trockenbauwände und Decken an.
                </p>
              </div>
              <div class="service-tile-image-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <section id="reference" class="scroll-padding">  
    <div class="container">  
      <h2>Referenzen</h2>  
      <CustomerQuotes />  
    </div>  
  </section>  
    <section id="contact">  
      <div class="container">  
        <Contact />  
      </div>  
    </section>  
  </div>
</template>  
  
<script>
import CustomerQuotes from "@/components/CustomerQuotes.vue";  
import Contact from '@/components/Contact.vue';  
import main1 from "@/assets/main1.jpg";
import main2 from "@/assets/main2.jpg";
import main3 from "@/assets/main3.jpg";



  export default {  
    name: 'MainView',  
    components: {
      CustomerQuotes,  
      Contact,  
    },
    data() {
    return {
      mainImages: [main1, main2, main3]
    };
  },  computed: {
    randomMainImage() {
      return this.mainImages[Math.floor(Math.random() * this.mainImages.length)];
    }
  },
  
  };  
</script>  
  
<style scoped>  #hero {
    padding: 100px 0;
  }

  #about,
  #services,
  #reference {
    padding: 50px 0;
  }

  h1,
  h2 {
    margin-bottom: 20px;
  }

  .parallax {
    /* The image used for parallax effect */
    /* background-image: url("https://source.unsplash.com/random"); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
    /* Set the height of the parallax section */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* Set the text color */
  }

  .service-tile {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
  }

  .service-tile:hover {
    transform: scale(1.1) !important;
  }

  .service-tile h3 {
    margin-bottom: 10px;
  }

  .service-tile-content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #fff;
  }

  .service-tile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .service-tile:hover .service-tile-image {
    opacity: 1;
  }

  .service-tile:hover .service-tile-content {
    color: #ffffff;
  }

  .service-tile:hover .service-tile-content h3 {
    color: #ffffff;
  }

  .service-tile-image-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .service-tile:hover .service-tile-image-overlay {
    opacity: 1;
  }
  .container {
    margin-top: 30px;
  }

  h1.animate__animated {
    font-size: 4rem;
  }

  p.animate__animated {
    font-size: 2rem;
  }

  .title_text {
    color: #b11616;
    text-shadow: 1px 1px 2px #531e1e;
  }

</style>  