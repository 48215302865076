<template>  
  <div id="app" class="projects-container">  
    <h2>Projekte</h2>
  </div>  
  <SimpleGallery galleryID="my-test-gallery" :images="images" />
</template>  
    
<script>  
import SimpleGallery from '@/components/SimpleGallery.vue'; 

function importImages(r) {
  return r.keys().map(r);
}

export default {  
  name: 'ProjectsView',  
  components: {
    SimpleGallery,
  },
  data() {
    const thumbnails = importImages(require.context('@/assets/projects/thumbnails/', false, /optimized_thumb_.*\.jpg$/));
    const larges = importImages(require.context('@/assets/projects/large/', false, /optimized_.*\.jpg$/));
    
    const images = thumbnails.map((thumbnail, index) => {
      return {
        largeURL: larges[index],
        thumbnailURL: thumbnail,
        width: 1500, // Set the actual width of the image
        height: 2000, // Set the actual height of the image
      };
    });

    return {
      images,
    };
  },
};
</script>

<style scoped>
.projects-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 20px;
  line-height: 1.6;
}

h2, h3 {
  margin-bottom: 10px;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>