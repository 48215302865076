<template>
    <nav class="navbar navbar-expand-lg fixed-top"
        :class="{ 'navbar-large': isLargeNavbar, 'navbar-small': !isLargeNavbar }">
        <div class="container px-5">
            <router-link class="navbar-brand" to="/" v-show="!isLargeNavbar">
                A.Z. Trockenbau
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li>
                        <a class="nav-link" :href="fullBaseUrl('#about')" @click.prevent="scrollTo('#about')">Über uns</a>
                    </li>
                    <li>
                        <a class="nav-link" :href="fullBaseUrl('#services')"
                            @click.prevent="scrollTo('#services')">Dienstleistungen</a>
                    </li>
                    <li>
                        <a class="nav-link" :href="fullBaseUrl('#reference')"
                            @click.prevent="scrollTo('#reference')">Referenzen</a>
                    </li>
                    <li>
                        <router-link class="nav-link" to="/projects">Projekte</router-link>
                    </li>
                    <li>
                        <a class="nav-link" :href="fullBaseUrl('#contact')"
                            @click.prevent="scrollTo('#contact')">Kontakt</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
  
<script>
export default {
    name: 'NavigationBar',
    data() {
        return {
            isLargeNavbar: true,
        };
    },
    methods: {
        handleScroll() {
            this.isLargeNavbar = window.scrollY <= 50;
        },
        scrollTo(selector) {
  const targetUrl = this.fullBaseUrl(selector);
  if (window.location.href === targetUrl) {
    const element = document.querySelector(selector);
    const yOffset = -50; // Adjust this value to change the offset
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  } else {
    window.location.href = targetUrl;
  }
},


fullBaseUrl(selector) {
  const { protocol, host } = window.location;
  const baseUrl = `${protocol}//${host}`;
  return `${baseUrl}/${selector}`;
},

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
  
<style scoped>
.navbar-large {
    background-color: transparent;
    padding: 10px 0;
    transition: all 0.3s;
}

.navbar-small {
    background-color: #e74c3c;
    padding: 10px 0;
    transition: all 0.3s;
}

.navbar-small .nav-link {
    color: #ffffff;
    /* White text color for navbar-small */
}

.navbar-large .nav-link:hover {
    color: #ff6b6b;
    /* Lighter shade of red for navbar-large hover color */
    transition: color 0.3s;
}

.navbar-small .nav-link:hover {
    color: #000;
    /* Lighter shade of red for navbar-large hover color */
    transition: color 0.3s;
}

.navbar-brand {
    color: #ffffff;
}

.navbar-brand:hover {
    color: #000;
}

.scroll-padding {
    padding-top: 50px;
    /* The same value as yOffset */
    margin-top: -50px;
    /* The negative value of yOffset */
}

@media (min-width: 992px) {
    .navbar-collapse {
        flex-grow: 0;
    }
}
</style>
  